
import React, { useContext, useEffect, useRef, useState } from "react";
import { GrNext } from "react-icons/gr";
import { GameContext } from "../Context/GameContext";
import { GrPrevious } from "react-icons/gr";
import { useLocation, useNavigate } from "react-router-dom";

const Confirmation = ({from}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalIdRef = useRef(null);
  const {setConfirmation,confirmation}=useContext(GameContext)
  const [doNotShow, setDoNotShow] = useState(false);
  const navigate=useNavigate();
 

  const images=[ 
    '/img/spotlight_page-1.jpg',
    '/img/spotlight_page-2.jpg',
    '/img/spotlight_page-3.jpg',
    '/img/spotlight_page-4.jpg',
    '/img/spotlight_page-5.jpg',
    '/img/spotlight_page-6.jpg',
    '/img/spotlight_page-7.jpg',
    '/img/spotlight_page-8.jpg',
    '/img/spotlight_page-9.jpg',
    '/img/spotlight_page-10.jpg',
    '/img/spotlight_page-11.jpg',
    '/img/spotlight_page-12.jpg',
    '/img/spotlight_page-13.jpg',
    '/img/spotlight_page-14.jpg',
    ]


    const handleClick=()=>{
      setConfirmation(true)
      if(from==="menu"){
        navigate('/')
      }
    }
      const handleCheckboxChan = (event) => {
          setDoNotShow(event.target.checked);
          localStorage.setItem("presentationDismissed", "true");
          if(from==="menu"){
            navigate('/')
          }
    setConfirmation(true);
        };
      
     const goToNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    };
  

    const goToPrevious = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    };

 useEffect(() => {
  intervalIdRef.current = setInterval(goToNext, 9000); // Assign to ref

  return () => clearInterval(intervalIdRef.current); // Clear interval
}, [currentIndex]);

// Function to reset the interval on user interaction
const resetInterval = () => {
  clearInterval(intervalIdRef.current);
  intervalIdRef.current = setInterval(goToNext, 9000); // Reassign to ref
};

  
  return (
    <div className="confirm">
    <div className="carousel" style={{ position: "relative"}}>
    {/* Image */}
    <img src={images[currentIndex]} alt={`Image ${currentIndex}`} style={{ width: "100%" }} />
    <div className="arrow" >

    
    {/* Previous button */}
    {currentIndex > 0 && 
    <button
      onClick={() => { goToPrevious(); resetInterval();}}
       style={{borderRadius:"100%" }}
    >
     <GrPrevious style={{ padding: "2px",height: "36px",width: "27px"}}/>
    </button>
}
   
    {/* Next button */}
    {currentIndex < images.length-1 &&
    <button
      onClick={() => { goToNext(); resetInterval(); }}
      style={{borderRadius:"100%" }}
      //  style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)",borderRadius:"100%" }}
    >
   <GrNext style={{ padding: "2px",height: "36px",width: "27px"}}/>
    </button>
}
</div>
  </div>


  <div className="button">
      <div onClick={handleClick}  className="btn1">Cliquez-ici pour continuer</div>           
      <label  className="btn1">             
      <input
              type="checkbox"
              checked={doNotShow}
              onChange={handleCheckboxChan}
           />
            {" "}
            Ne plus afficher
          </label>
        </div>
  
             </div>
  
  );
};


export default Confirmation;

